<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";

/**
 * Starter component
 */
export default {
  head () {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data () {
    return {
      title: "大首頁",
      items: [
        {
          text: "行銷版位管理",
          href: "/",
        },
        {
          text: "大首頁",
          active: true,
        }
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
};
</script>

<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12
      .card
        .card-body
          img.home-img(src="@/assets/images/home_sections-entries-bg.c424f55d.jpg", alt='')
          .btn-list
            router-link(to='/home-part-first')
              b-button.btn.btn-1(size="lg", variant="primary") 編輯第一區
            router-link(to='/home-part-second')
              b-button.btn.btn-2(size="lg", variant="primary") 編輯二區
            router-link(to='/home-part-third')
              b-button.btn.btn-3(size="lg", variant="primary") 編輯三宮格區
            router-link(to='/home-part-vote')
              b-button.btn.btn-4(size="lg", variant="primary") 編輯投票區
            router-link(to='/home-part-video')
              b-button.btn.btn-5(size="lg", variant="primary") 編輯影音新聞區
</template>
<style lang="sass" scoped>
.card-body
  display: flex
  position: relative
  width: 100%
  img.home-img
    width: 100%
    max-width: 900px
  .btn-list
    width: 100%
    display: flex
    flex-direction: column
    margin-left: 20px
    .btn
      position: absolute
    .btn-1
      top: 7%
    .btn-2
      top: 20%
    .btn-3
      top: 34%
    .btn-4
      top: 70%
    .btn-5
      top: 93%

@media (max-width: 1350px)
  .card-body
    .btn-list
      margin-left: 0
      position: absolute
      top: 1.5rem
      left: 1.5rem
      width: calc(100% - 3rem)
      max-width: 900px
      height: 100%

      .btn
        position: absolute
        left: 50%
        transform: translate(-50%,-55%)
</style>
