import { render, staticRenderFns } from "./home-index.vue?vue&type=template&id=2c741392&scoped=true&lang=pug"
import script from "./home-index.vue?vue&type=script&lang=js"
export * from "./home-index.vue?vue&type=script&lang=js"
import style0 from "./home-index.vue?vue&type=style&index=0&id=2c741392&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c741392",
  null
  
)

export default component.exports